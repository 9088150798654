import React from "react"
import { UserProvider } from "./src/lib/context/user"
import { AnimatePresence } from "framer-motion"
// custom typefaces

// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

export const wrapRootElement = ({ element }) => (
  <UserProvider>
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </UserProvider>
)
