import { Client, Account, Databases, Storage } from "appwrite"

// Initialize the Appwrite client
const client = new Client()

client
  .setEndpoint(process.env.GATSBY_APPWRITE_ENDPOINT) // Use environment variable for endpoint
  .setProject(process.env.GATSBY_APPWRITE_PROJECT_ID) // Use environment variable for project ID

// Initialize Appwrite services
const account = new Account(client)
const databases = new Databases(client)
const storage = new Storage(client)

export { client, account, databases, storage }
