// src/lib/context/user.jsx
import React, { createContext, useContext, useEffect, useState } from "react"
import { ID, Query, OAuthProvider } from "appwrite"
import { account, databases, storage } from "../../utils/appwrite"

const UserContext = createContext()

export function useUser() {
  return useContext(UserContext)
}

export function UserProvider(props) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const DATABASE_ID = process.env.GATSBY_APPWRITE_DATABASE_ID
  const USERS_COLLECTION_ID = process.env.GATSBY_APPWRITE_USERS_COLLECTION_ID
  const BUCKET_ID = process.env.GATSBY_APPWRITE_BUCKET_ID

  // Update user profile
  async function updateProfile(updatedFields) {
    try {
      const updatedUser = await databases.updateDocument(
        DATABASE_ID,
        USERS_COLLECTION_ID,
        user.$id,
        updatedFields,
      )
      setUser(updatedUser)
      return updatedUser
    } catch (error) {
      console.error("Profile update error:", error)
      throw error
    }
  }

  // Upload avatar image
  async function uploadAvatar(file) {
    try {
      const fileUpload = await storage.createFile(BUCKET_ID, ID.unique(), file)
      const imageUrl = storage.getFileView(BUCKET_ID, fileUpload.$id)
      return imageUrl
    } catch (error) {
      console.error("Avatar upload error:", error)
      throw error
    }
  }

  // Registration
  async function register(
    email,
    password,
    name,
    username,
    bio = "",
    imageUrl = null,
  ) {
    try {
      const usernameCheck = await databases.listDocuments(
        DATABASE_ID,
        USERS_COLLECTION_ID,
        [Query.equal("username", username)],
      )

      if (usernameCheck.total > 0) {
        throw new Error("Этот никнейм уже занят.")
      }

      const emailCheck = await databases.listDocuments(
        DATABASE_ID,
        USERS_COLLECTION_ID,
        [Query.equal("email", email)],
      )
      if (emailCheck.total > 0) {
        throw new Error("Этот email уже зарегистрирован.")
      }

      const userAccount = await account.create(
        ID.unique(),
        email,
        password,
        name,
      )

      const userDoc = await databases.createDocument(
        DATABASE_ID,
        USERS_COLLECTION_ID,
        userAccount.$id,
        { name, username, accountId: userAccount.$id, email, bio, imageUrl },
      )

      await login(email, password)
      return userDoc
    } catch (error) {
      if (error.message.includes("Duplicate entry")) {
        throw new Error("Этот email или имя пользователя уже заняты.")
      }
      console.error("Ошибка регистрации:", error)
      throw new Error(error.message)
    }
  }

  async function login(email, password) {
    try {
      await account.createEmailPasswordSession(email, password)
      await fetchUserData()
      window.location.replace("/")
    } catch (error) {
      console.error("Ошибка входа:", error)
      throw new Error("Неверный email или пароль.")
    }
  }

  async function logout() {
    try {
      await account.deleteSession("current")
      setUser(null)
      localStorage.removeItem("user")
      window.location.replace("/")
    } catch (error) {
      console.error("Ошибка выхода:", error)
      throw new Error("Не удалось выйти. Пожалуйста, попробуйте снова.")
    }
  }

  async function fetchUserData() {
    try {
      const loggedIn = await account.get()
      const userDoc = await databases.getDocument(
        DATABASE_ID,
        USERS_COLLECTION_ID,
        loggedIn.$id,
      )
      setUser(userDoc)
    } catch (error) {
      if (error.code === 401) {
        console.warn(
          "Unauthorized request to fetch account data. No session is active.",
        )
      } else {
        console.error("Fetch user data error:", error)
      }
      setUser(null) // Handle unauthenticated users gracefully
    }
  }

  async function refreshUser() {
    try {
      const loggedIn = await account.get()
      const userDoc = await databases.getDocument(
        DATABASE_ID,
        USERS_COLLECTION_ID,
        loggedIn.$id,
      )
      setUser(userDoc)
    } catch (error) {
      if (error.code !== 401) {
        console.error("Refresh user error:", error)
      }
      setUser(null)
    }
  }

  async function init() {
    setLoading(true)
    try {
      // Validate current session
      const loggedIn = await account.get()
      const userDoc = await databases.getDocument(
        DATABASE_ID,
        USERS_COLLECTION_ID,
        loggedIn.$id,
      )
      setUser(userDoc)
    } catch (error) {
      if (error.code === 401) {
        console.warn(
          "No active session found (Unauthorized). User is not logged in.",
        )
      } else {
        console.error("Session check failed:", error)
      }
      setUser(null)
      localStorage.removeItem("user") // Clear stale cached data
    }
    setLoading(false)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user))
    } else {
      localStorage.removeItem("user")
    }
  }, [user])

  async function loginWithGoogle() {
    try {
      await account.createOAuth2Session(
        OAuthProvider.Google,
        `${window.location.origin}/success`,
        `${window.location.origin}/failed`,
      )
    } catch (error) {
      console.error("Google login error:", error)
    }
  }

  return (
    <UserContext.Provider
      value={{
        current: user,
        login,
        logout,
        register,
        refreshUser,
        loginWithGoogle,
        updateProfile,
        uploadAvatar,
        loading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
